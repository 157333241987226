import Cv from "../posts/cv.md";
import Mariage from "../posts/mariage.md";
import Stagiaire from "../posts/stagiaire.md";

const Posts = [
  {
    title:
      "L'intérêt de proposer des stages en tant que dév’ freelance : une perspective win-win !",
    date: "25 septembre 2023",
    author: "AlizaryAna",
    description:
      "Retour sur mon expérience et mes réflexions : pourquoi proposer des stages en tant que développeur freelance est une excellente idée ? ",
    image:
      "https://img.freepik.com/photos-gratuite/personnes-pratiquant-integration-sociale-dans-espace-travail_23-2149405379.jpg?t=st=1695631360~exp=1695631960~hmac=53e27cac3c866a3dc8c5b5d71fccfb3d095a4cee8d7f3ee4d8a727331d31dec4",
    content: Stagiaire,
    route: "stagiaire",
  },

  {
    title:
      "Dev' web et préparatifs de mariage : la bonne idée insoupçonnée !  ",
    date: "19 septembre 2023",
    author: "AlizaryAna",
    description:
      "Découvrez pourquoi faire appel à un ou une professionnelle du développement web peut être une décision judicieuse pour de futurs mariés.",
    image:
      "https://img.freepik.com/photos-gratuite/bobine-ficelle-portable-alliances-fleurs-haleine-bebe_23-2147936515.jpg?w=1060&t=st=1694614031~exp=1694614631~hmac=05f399bc313991f20233b0be348e84d372642685d01c5b7a31ce3b12861367d9",
    content: Mariage,
    route: "mariage",
  },

  {
    title: "L'importance du CV en ligne",
    date: "6 septembre 2023",
    author: "AlizaryAna",
    description:
      "Voyons ensemble pourquoi il est aujourd'hui important, voire crucial d'avoir un CV sur la toile, comment et où le diffuser et l'attention particulière dont il faut faire preuve pour la confidentialité de ses données.",
    image:
      "https://img.freepik.com/photos-gratuite/curriculum-vitae-cv-concept-demande-emploi_53876-127763.jpg?w=740&t=st=1693990101~exp=1693990701~hmac=ce5fff59f0664f5bc69f718e67edf8be41b6739305a5fbaff6e6a19f145dd0d1",
    content: Cv,
    route: "cv_en_ligne",
  },
];

export default Posts;
