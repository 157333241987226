import React from 'react';
import { socialButtons } from './socialButtons.css';

const SocialButtons = () => {
    return (
        <div className='liensRS'>
            <a href="https://alizaryana.com" target="_blank"><i class="fa-solid fa-globe" data-label="Mon site"></i></a>
			<a href="https://github.com/Alizarya" target="_blank"><i class="fa-brands fa-square-github" data-label="GitHub"></i></a>
			<a href="https://www.linkedin.com/in/ana-alizarya-036877264/" target="_blank"><i class="fa-brands fa-linkedin" data-label="LinkedIn"></i></a>
			<a href="https://twitter.com/AlizaryAna" target="_blank"><i class="fa-brands fa-square-x-twitter" data-label="X (ex Twitter)"></i></a>
			<a href="https://bsky.app/profile/alizaryana.bsky.social" target="_blank"><i class="fa-solid fa-square" data-label="BlueSky"></i></a>
			<a href="/contact"><i class="fa-solid fa-square-envelope" data-label="Me contacter"></i></a>
        </div>
    );
}

export default SocialButtons;
