import React from "react";

//Import des styles
import {contactForm} from "./contactForm.css"
import {Input, TextArea} from '../StyledComponents/StyledComponents';
import Button from '../Utility/Button';

class ContactForm extends React.Component {

  handleButtonClick = (event) => {
    event.preventDefault();

  	// Récupérer les valeurs des champs du formulaire
  	const name = document.getElementById("name").value;
  	const email = document.getElementById("email").value;
  	const objet = document.getElementById("objet").value;
  	const message = document.getElementById("message").value;

	// Créer l'objet contenant les données du formulaire
	const formData = {
		name: name,
		email: email,
		objet: objet,
		message: message,
	};

	// // Envoyer les données du formulaire
	fetch("https://api.alizaryana.com/contact", {
		method: "POST",
		headers: {
		"Content-Type": "application/json",
		},
		body: JSON.stringify(formData),
	})
	.then((response) => response.json())
    .then((data) => {
      // Traiter la réponse de l'API ici
        if (data.success) {
        // Afficher une alerte de succès si le message a été envoyé
        alert(
          "Votre message a bien été envoyé ! Une réponse vous sera apportée dans les plus brefs délais."
        );
		// Vider les champs du formulaire
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
        document.getElementById("objet").value = "";
        document.getElementById("message").value = "";
      } else {
        // Afficher une alerte d'erreur en cas d'échec d'envoi
        alert(
          "Une erreur s'est produite lors de l'envoi du message. Veuillez réessayer ultérieurement."
        );
      }
    })


	}

  

  render() {
    return (
      <form>
		<p className="labelForm">Nom/Prénom</p>
        <Input id="name" autoComplete="off" label="Nom/Prénom" />
		<p className="labelForm">Adresse e-mail</p>
        <Input id="email" autoComplete="off" label="Adresse mail" />
		<p className="labelForm">Objet du message</p>
        <Input id="objet" autoComplete="off" label="Objet du message" />
		<p className="labelForm">Message</p>
        <TextArea id="message" autoComplete="off" label="Message" textArea/> 
        <Button
          right
          onClick={this.handleButtonClick}
          text="Envoyer votre message"
        />
      </form>
    );
  }
}

export default ContactForm;
