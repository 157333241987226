import React from 'react';
import {
    DesktopLogo,
    MobileLogo,
    NavLogo
} from '../../components/Navigation/Style';
import Data from '../../Data';


const Logo = (props) => (
    <NavLogo href="/home">
        <DesktopLogo className='logo'>
        <img 
                className='logoBlanc' 
                src="./images/logo-blanc.png" 
                alt="Logo Alizaryana"
                style={{ width: '30px', marginRight: '20px' }}
            />
        {Data.home.name}
        
        </DesktopLogo>
        <MobileLogo>
        <img 
                className='logoBlanc' 
                src="./images/logo-blanc.png" 
                alt="Logo Alizaryana"
                style={{ width: '30px'}}
            />
        </MobileLogo>
    </NavLogo> 
);

export default Logo;
