import React from 'react';
import Logo from '../Logo/Logo';
import SocialButtons from '../Social/SocialButtons';
import {
	NavContainer,
	Nav,
	SubNavMenu,
	SubNavMenuList,
} from '../../components/Navigation/Style';
import { BodyContainer } from '../StyledComponents/StyledComponents';

class Navigation extends React.Component {

	render() {
    	return (
			<nav>
				<NavContainer>
					<BodyContainer>
						<Nav>
							<Logo color={this.props.logoColor} size="36px" />
							<SubNavMenu>
								<SubNavMenuList>
								<SocialButtons></SocialButtons>
								</SubNavMenuList>
							</SubNavMenu>
						</Nav>
					</BodyContainer>
				</NavContainer>
			</nav>
    	)
  	}
}

export default Navigation;
