// Import des styles
import {share} from "./share.css"

import React from 'react';

const Share = () => {

  const handleShareClick = (socialNetwork) => {
    const articleURL = window.location.href;

    switch (socialNetwork) {
      case "LinkedIn":
        window.open(`https://www.linkedin.com/shareArticle?url=${articleURL}`);
        break;
      case "Facebook":
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${articleURL}`);
        break;
      case "Twitter":
        window.open(`https://twitter.com/intent/tweet?url=${articleURL}`);
        break;
      case "WhatsApp":
        window.open(`https://api.whatsapp.com/send?text=${articleURL}`);
        break;
      case "Copier":
        const urlToCopy = articleURL;
        const tempInput = document.createElement("input");
        tempInput.value = urlToCopy;
        tempInput.style.position = "absolute";
        tempInput.style.left = "-9999px";
        document.body.appendChild(tempInput);
        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // Pour les navigateurs mobiles
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        break;
      default:
        break;
    }
  }

  return (
    <div className="mainFooter">
      <p>Vous aimez cet article ? Faites le savoir ! </p>
      <div className="share">
        <p>Partager sur</p>
        <div className="shareLogos">
          <i className="fab fa-linkedin share-icon" data-social="LinkedIn" onClick={() => handleShareClick("LinkedIn")}></i>
          <i className="fa-brands fa-square-facebook share-icon" data-social="Facebook" onClick={() => handleShareClick("Facebook")}></i>
          <i className="fa-brands fa-square-x-twitter share-icon" data-social="Twitter" onClick={() => handleShareClick("Twitter")}></i>
          <i className="fa-brands fa-square-whatsapp share-icon" data-social="WhatsApp" onClick={() => handleShareClick("WhatsApp")}></i>
          <i className="fa-solid fa-copy share-icon" data-social="Copier" onClick={() => handleShareClick("Copier")}></i>
        </div>
      </div>
    </div>
  );
}

export default Share;
