const About = {
  about: {
    title:
      "Je suis surement coincée quelque part entre Procreate, JavaScript, et une multitude de post-it !",
    description:
      "A la suite d'un bac S option mathématiques, des études d'économie et de commerce, une carrière dans le domaine bancaire, puis un virage dans l’écriture, je me suis lancée dans la grande aventure de la reconversion en 2022. ",
    description1:
      "Passionnée de sciences et de nouvelles technologies, mais dotée d’un fort esprit créatif, je me suis naturellement tournée vers les métiers du développement web. ",
    description2:
      "Je propose désormais mes services pour la création,la production et la maintenance de sites internet, conçus sur mesure et parfaitement adaptés aux besoins spécifiques de mes clients et clientes.",
    experience: [
      {
        title: "Développeuse web freelance",
        company: "",
        date: "2023 - Aujourd'hui",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      },
      {
        title: "Prête plume",
        company: "",
        date: "2014 - 2022",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      },
      {
        title: "Responsable de banque",
        company: "",
        date: "2008 - 2016",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      },
    ],
    education: [
      {
        degree: "",
        program: "",
        school: "",
        date: "",
        about: "",
      },
      {
        degree: "",
        program: "",
        school: "",
        date: "",
        about: "",
      },
    ],
  },
  contact: {
    title: "M'envoyer un message",
    description:
      "Pour toute question, je reste disponible afin que nous puissions échanger ensemble. Vous pouvez utiliser ce formulaire afin de m'envoyer directement un mail.",
    email: "",
    formspree: "",
  },
  home: {
    name: " AlizaryAna",
    title: "Le blog d'une créatrice et développeuse web passionnée.",
    description:
      "Plongez dans les méandres de mes réflexions de développeuse web passionnée, où je partage et analyses les tendances et techniques du monde du dev'. Explorez avec moi les nuances et défis de cette discipline en constante évolution, et découvrez ce qui me passionne tant !",
  },
  social: {},
};

export default About;
